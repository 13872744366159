import React, { useEffect, useState } from "react";
import ControlledBox from "../../components/box/controlled-box";
import { Box, FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import Button from "../../components/button/button";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "../../components/dialog/dialog";
import IconButton from "../../components/icon-button";
import Selector from "../../components/select/select";
import { clockData } from "../../config/constant";
import { Dayjs } from "dayjs";
import { convertTo24Hour } from "../../utils/utils";
import DateCalendarServerRequest from "../../components/date-range-picker/date-range-picker";

type HolidaysProps = {
  day: "";
  fullDate: "";
  start: "8:00";
  end: "5:00";
  morning: "am";
  midnight: "pm";
};
export default function Holidays() {
  const [open, setOpen] = useState(false);
  const [index, setIndex] = useState(0);
  const [selectedDays, setSelectedDays] = React.useState<Dayjs[]>([]);
  const [holidays, setHolidays]: HolidaysProps | any = useState([
    {
      first_name: "John",
      last_name: "Doe",
      email: "john.doe@update.com",
      role: "owner",
      callback_request: false,
      appointments: true,
      hot_leads: false,
      first_responder: true,
      dealer_assistant_request: true,
    },
  ]);

  const toggleDialog = () => {
    setOpen(!open);
  };

  const formatSelectedDays = () => {
    const updatesItems: any = [];
    selectedDays.map((day: { format: (arg0: string) => any }) => {
      const data = {
        day: day.format("dddd"),
        fullDate: day.format("MMMM D, YYYY"),
        start: "8:00",
        end: "5:00",
        morning: "am",
        midnight: "pm",
      };
      updatesItems.push(data);
    });
    setHolidays(updatesItems);
  };

  useEffect(() => {
    formatSelectedDays();
  }, [selectedDays]);

  const renderContent =
    index === 0 ? (
      <DateCalendarServerRequest selectedDays={selectedDays} setSelectedDays={setSelectedDays} />
    ) : (
      <Box display={"flex"} flexDirection={"column"}>
        {holidays.map((h: HolidaysProps, i: number) => (
          <Box width={"100%"} display={"flex"} gap={1} key={i}>
            <Box
              sx={{
                border: "1px solid #E3E3E3",
                p: 1,
                borderRadius: 2,
              }}
            >
              <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                <Typography>{h.day}</Typography>
                <IconButton icon={<CloseIcon />} />
              </Box>
              <Typography>{h.fullDate}</Typography>
              <Box
                width={113}
                height={80}
                border={"1px solid #E3E3E3"}
                display={"flex"}
                justifyContent={"center"}
                flexDirection={"column"}
                gap={1}
                borderRadius={1}
                p={1}
                my={1}
              >
                <Typography display={"flex"} variant="body2" color={"#7D7F80"}>
                  {h.day}
                </Typography>
                <Typography display={"flex"} variant="body2" color={"#7D7F80"}>
                  {convertTo24Hour(h.start, h.morning)} - {convertTo24Hour(h.end, h.midnight)}
                </Typography>
              </Box>
            </Box>
            <Box display={"flex"} flexDirection={"column"}>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="radio-buttons-group"
              >
                <FormControlLabel
                  value="owner"
                  control={<Radio />}
                  label=" Dealership will be closed on the selected dates"
                />
                <FormControlLabel
                  value="owner"
                  control={<Radio />}
                  label="  i want to change hours.."
                />
                <FormControlLabel
                  value="staff"
                  control={<Radio />}
                  label="i want to appointment only"
                />
              </RadioGroup>
            </Box>
            <Box display={"flex"}>
              <Box ml={1}>
                <Typography>From</Typography>
                <Box display={"flex"} gap={1}>
                  <Selector
                    data={clockData}
                    width={85}
                    value={"0:00"}
                    // onChange={(e: SelectChangeEvent) =>
                    //   handleChangeDay(w.day, {
                    //     start: e.target.value,
                    //     morning: w.morning,
                    //     end: w.end,
                    //     midnight: w.midnight,
                    //   })
                    // }
                  />
                  <Selector
                    data={["am", "pm"]}
                    width={85}
                    value={"0:00"}
                    // onChange={(e: SelectChangeEvent) =>
                    //   handleChangeDay(w.day, {
                    //     start: w.start,
                    //     morning: e.target.value,
                    //     end: w.end,
                    //     midnight: w.midnight,
                    //   })
                    // }
                  />
                </Box>
              </Box>
              <Box ml={2}>
                <Typography>To</Typography>
                <Box display={"flex"} gap={1}>
                  <Selector
                    data={clockData}
                    width={85}
                    value={"0:00"}
                    // onChange={(e: SelectChangeEvent) =>
                    //   handleChangeDay(w.day, {
                    //     start: w.start,
                    //     morning: w.morning,
                    //     end: e.target.value,
                    //     midnight: w.midnight,
                    //   })
                    // }
                  />
                  <Selector
                    data={["am", "pm"]}
                    width={85}
                    value={"0:00"}
                    // onChange={(e: SelectChangeEvent) =>
                    //   handleChangeDay(w.day, {
                    //     start: w.start,
                    //     morning: w.morning,
                    //     end: w.end,
                    //     midnight: e.target.value,
                    //   })
                    // }
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
    );

  return (
    <>
      <Box>
        <Typography color={"#505050"} variant="h6">
          Add Holidays
        </Typography>
        <Typography color={"#9B9B9B"} variant="body1">
          Specify dates you are closed due to holidays or other reasons
        </Typography>
      </Box>
      <ControlledBox sx={{ justifyContent: "center" }} display={"flex"}>
        <Button variant="outlined" color="primary" onClick={toggleDialog}>
          Add custom time and date
        </Button>
      </ControlledBox>
      <Box display={"flex"} justifyContent={"left"} width={"100%"} gap={1}>
        <Button variant="outlined" color="primary">
          Cancel
        </Button>
        <Button variant="contained" color="primary">
          Save Change
        </Button>
      </Box>
      <Dialog
        maxWidth={"lg"}
        open={open}
        handleClose={toggleDialog}
        fullWidth
        renderContent={renderContent}
        renderTitle={
          <Box>
            <Typography variant="h6" fontWeight={700}>
              {index === 0 ? "1.Choose Dates" : "2.Choose Options"}
            </Typography>
            <Typography variant="body1" color={"#686868"} fontWeight={500}>
              {index === 0
                ? " You can choose one or more different days."
                : "You can apply the desired options for your selected days."}
            </Typography>
          </Box>
        }
        renderActions={
          <Box display={"flex"} justifyContent={"center"} width={"100%"} gap={1}>
            <Button variant="outlined" color="primary" onClick={() => setIndex(0)}>
              Cancel
            </Button>
            <Button variant="contained" color="primary" onClick={() => setIndex(1)}>
              Next
            </Button>
          </Box>
        }
      />
    </>
  );
}
