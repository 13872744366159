import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { getToken, getUid } from "../../redux/authSlice";
import Button from "../../components/button/button";
import Notifications from "./notification";
import { getData } from "../../utils/firebase";
import DealershipInformation from "./dealership-information";

const initialData = [
  {
    first_name: "",
    last_name: "",
    phone_number: "+1111111111",
    role: "owner",
    callback_request: false,
    appointments: true,
    hot_leads: false,
    first_responder: true,
    dealer_assistant_request: true,
  },
];

export default function Dealership() {
  const [dealerShip, setDealership]: any = useState();
  const [notifications, setNotifications]: any = useState([]);
  const token = useSelector(getToken);
  const uid = useSelector(getUid);

  const handleGetData = (data: any) => {
    setDealership(data);
  };

  const handleGetNotifications = (data: any) => {
    if (!data) return;
    // Collect notifications from object keys
    const notificationsToAdd = Object.keys(data).map((key) => ({
      id: key, // Use the key as the unique identifier
      ...data[key], // Spread the notification data associated with the key
    }));
    // Update the state in one go, filtering out duplicates
    setNotifications((prevItems: any) => {
      const existingIds = new Set(prevItems.map((item: { id: any }) => item.id)); // Assuming each notification has a unique 'id'
      // Filter out duplicates and return new notifications
      const newNotifications = notificationsToAdd.filter(
        (notification) => !existingIds.has(notification.id)
      );
      return [...prevItems, ...newNotifications]; // Combine existing and new notifications
    });
  };

  const checkNotificationStatus = () => {
    const shouldUpdates: [] = notifications.filter((notification: { id: any }) => notification.id);
    const shouldCreates: [] = notifications.filter((notification: { id: any }) => !notification.id);
    if (shouldUpdates.length > 0) {
      shouldUpdates.forEach((u) => updateNotifications(u));
    }
    if (shouldCreates.length > 0) {
      createNotifications(shouldCreates);
    }
  };

  const postData = () => {
    postDealershipInfo();
    checkNotificationStatus();
  };

  useEffect(() => {
    getData(`dealers/${uid}/settings/user_info`).then((r: any) => handleGetData(r));
    getData(`dealers/${uid}/settings/notification`).then((r: any) => handleGetNotifications(r));
  }, []);

  const postDealershipInfo = async () => {
    // Create an Axios instance with a base URL
    const apiClient = axios.create({
      baseURL: process.env.REACT_APP_BASE_URL, // Replace with your base URL
      headers: {
        Authorization: ` ${token}`, // Replace with your actual token
        "Content-Type": "application/json",
      },
    });
    const data = {
      section: "user_info",
      data: dealerShip,
    };
    try {
      const response = await apiClient.post("", data);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };

  const createNotifications = async (values: any) => {
    // Create an Axios instance with a base URL
    const apiClient = axios.create({
      baseURL: process.env.REACT_APP_BASE_URL, // Replace with your base URL
      headers: {
        Authorization: ` ${token}`, // Replace with your actual token
        "Content-Type": "application/json",
      },
    });
    const data = {
      section: "notification_data",
      data: {
        notifs: values,
      },
    };
    try {
      const response = await apiClient.post("", data);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = ({ currentTarget: input }: any) => {
    const value = input.value;
    setDealership({ ...dealerShip, [input.name]: value });
  };

  const handleChangeNotification = ({ currentTarget: input }: any) => {
    const value = input.value;
    setNotifications({ ...notifications, [input.name]: value });
  };

  const updateNotifications = async (item: { id: any } | undefined) => {
    // Create an Axios instance with a base URL
    const apiClient = axios.create({
      baseURL: process.env.REACT_APP_BASE_URL, // Replace with your base URL
      headers: {
        Authorization: ` ${token}`, // Replace with your actual token
        "Content-Type": "application/json",
      },
    });

    const data = {
      section: "notification_data",
      notification_id: item?.id,
      data: {
        notifs: [item],
      },
    };
    try {
      const response = await apiClient.put("", data);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };

  const handleMorePerson = () => {
    setNotifications((prevItems: any) => {
      return [...prevItems, ...initialData]; // Combine existing and new notifications
    });
  };

  return (
    <>
      <DealershipInformation state={dealerShip} setState={setDealership} onChange={handleChange} />
      <Notifications
        state={notifications}
        setState={setNotifications}
        onChange={handleChangeNotification}
        onMorePerson={handleMorePerson}
      />
      <Box display={"flex"} justifyContent={"center"} width={"100%"} gap={1} mb={1}>
        <Button variant="outlined" color="primary">
          Cancel
        </Button>
        <Button variant="contained" color="primary" onClick={postData}>
          Save Change
        </Button>
      </Box>
    </>
  );
}
