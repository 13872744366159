import { Backdrop, Box, IconButton, Typography } from "@mui/material";

import React, { useEffect, useState } from "react";
import ErrorIcon from "@mui/icons-material/Error";

import { formatDateFrom } from "../../utils/time-convert";
import CloseIcon from "@mui/icons-material/Close";
import MessageWithClickableLink from "../../utils/message-with-clickable-link";
import { getMode } from "../../redux/layoutSlice";
import { useSelector } from "react-redux";
import PdfFileIcon from "../../assets/icon/pdf-file-icon";
import ImageDownloader from "../../components/image-downloader/image-downloader";
import { getFiles } from "../../redux/userSlice";
import PdfViewer from "../../components/viewer/pdf-viewer";

type PropTypes = {
  currentUser: any;
  value: any;
};

const ChatItem = ({ value }: PropTypes) => {
  const [openImage, setOpenImage] = useState(false);
  const [openPdf, setOpenPdf] = useState(false);
  const [type, setType] = useState("image");
  const files = useSelector(getFiles);
  const mode = useSelector(getMode);

  const handleClose = () => {
    setOpenImage(false);
  };

  const handleOpen = () => {
    setOpenImage(true);
  };

  const handleOpenPdf = () => {
    setOpenPdf(!openPdf);
  };

  useEffect(() => {
    if (value?.MMS) {
      const type = value?.mediaURL?.includes(".pdf");
      if (type) setType("pdf");
      else setType("image");
    }
  }, [value?.MMS]);

  const filePreview = files.find((item: { id: any }) => item.id === value.pushId);

  const handleName = (path: string) => {
    // Split the path by '/' and get the last element for the filename
    const parts = path.split("/");
    const filename = parts[parts.length - 1]; // This will give you 'camera_lense_0.jpeg'
    return filename;
  };

  return (
    <>
      <Box
        display={"flex"}
        alignItems={"center"}
        width={"100%"}
        height={"fit-content"}
        justifyContent={value?.receive ? "start" : "end"}
        p={1}
      >
        {!value?.MMS && (
          <Box display={"flex"} gap={1} overflow={"hidden"}>
            <Box
              sx={{
                width: "fit-content",
                display: { xs: "grid", sm: "grid" },
              }}
              height={"fit-content"}
              justifyItems={value?.receive ? "start" : "end"}
              textAlign={"start"}
            >
              <Box
                display={"flex"}
                bgcolor={(theme) =>
                  value?.receive
                    ? mode === "dark"
                      ? "#383838"
                      : "#CEEBFB"
                    : theme.palette.primary.main
                }
                mx={1}
                sx={{
                  maxWidth: 500,
                  borderRadius: value?.receive ? "10px 10px 10px 0" : "10px 10px 0 10px",
                  position: "relative",
                  ...(value?.receive && {
                    "&::before": {
                      content: '""',
                      position: "absolute",
                      width: 0,
                      height: 0,
                      right: "auto",
                      top: "auto",
                      left: -4,
                      bottom: -10,
                      border: "10px solid",
                      transform: "rotate(45deg)",
                      borderColor: () =>
                        `transparent transparent transparent ${mode === "dark" ? "#383838" : "#CEEBFB"}`,
                    },
                  }),

                  ...(!value?.receive && {
                    "&::before": {
                      content: '""',
                      position: "absolute",
                      width: 0,
                      height: 0,
                      left: "auto",
                      top: "auto",
                      right: -4,
                      bottom: -10,
                      border: "10px solid",
                      transform: "rotate(135deg)",
                      borderColor: (theme) =>
                        `transparent transparent transparent ${theme.palette.primary.main}`,
                    },
                  }),
                }}
              >
                {value?.content && (
                  <Typography
                    display={"flex"}
                    sx={{
                      wordBreak: "break-word",
                      maxWidth: 500,
                      borderRadius: 50,
                    }}
                    px={1}
                    variant="body2"
                    color={value?.receive ? "" : "white"}
                  >
                    <MessageWithClickableLink messageContent={value?.content} />
                  </Typography>
                )}
              </Box>
              <Typography
                color={"#8F92A1"}
                variant="caption"
                sx={{
                  display: "flex",
                  justifyContent: !value?.receive ? "end" : "start",
                }}
              >
                {formatDateFrom(value?.timestamp)}
                {value?.receive ? "" : `${value?.sender === "AI" ? " - AI" : " - Dealer"}`}
                {value?.status === -1 && (
                  <ErrorIcon
                    sx={{
                      color: "red",
                      width: "15px",
                    }}
                  />
                )}
              </Typography>
            </Box>
          </Box>
        )}
        {value?.MMS && type === "image" && (
          <>
            <Box
              display={"flex"}
              borderRadius={2}
              justifyContent={"start"}
              alignItems={"center"}
              p={2}
              bgcolor={(theme) =>
                value?.receive
                  ? mode === "dark"
                    ? "#383838"
                    : "#CEEBFB"
                  : theme.palette.primary.main
              }
            >
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignContent={"center"}
                component={"span"}
                sx={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  const exists = files.some((item: { id: any }) => item.id === value.pushId);
                  if (exists) handleOpen();
                }}
              >
                <ImageDownloader value={value?.mediaURL} id={value?.pushId} />
              </Box>
              <Typography color={"#07A4FC"}>{value?.content}</Typography>
            </Box>
          </>
        )}
        {value?.MMS && type === "pdf" && (
          <>
            <Box
              display={"flex"}
              borderRadius={2}
              justifyContent={"start"}
              alignItems={"center"}
              p={2}
              bgcolor={(theme) =>
                value?.receive
                  ? mode === "dark"
                    ? "#383838"
                    : "#CEEBFB"
                  : theme.palette.primary.main
              }
              flexDirection={"column"}
            >
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignContent={"center"}
                component={"span"}
                sx={{
                  cursor: "pointer",
                }}
              >
                <IconButton onClick={handleOpenPdf}>
                  <PdfFileIcon />
                </IconButton>
              </Box>
              <Typography variant="caption">{handleName(value?.mediaURL)}</Typography>
              <Typography color={"#07A4FC"}>{value?.content}</Typography>
            </Box>
          </>
        )}
      </Box>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openImage}
        onClick={handleClose}
      >
        <Box position={"absolute"} right={"1rem"} top={"1rem"}>
          <IconButton onClick={handleClose}>
            <CloseIcon sx={{ color: "white" }} />
          </IconButton>
        </Box>
        {openImage && (
          <>
            <img
              alt={"image"}
              loading="lazy"
              style={{
                maxHeight: window.innerHeight,
                maxWidth: window.innerWidth,
                width: "100%",
                height: "auto",
                objectFit: "contain",
              }}
              src={filePreview?.base64}
            />
          </>
        )}
      </Backdrop>
      <PdfViewer src={value?.mediaURL} open={openPdf} onClose={handleOpenPdf} />
    </>
  );
};

export default ChatItem;
