import "react-international-phone/style.css";

import {
  FormControl,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import {
  defaultCountries,
  FlagImage,
  parseCountry,
  usePhoneInput,
} from "react-international-phone";

const MuiPhone = ({ value, onChange, error, label, ...restProps }: any) => {
  const { inputValue, handlePhoneValueChange, inputRef, country, setCountry } = usePhoneInput({
    defaultCountry: "us",
    value,
    disableDialCodeAndPrefix: true,
    countries: defaultCountries,
    onChange: (data) => {
      onChange(data.phone);
    },
  });

  return (
    <FormControl fullWidth={true}>
      {label && (
        <Typography
          color={error ? "red" : "#505050"}
          fontSize={13}
          fontWeight={700}
          mb={1}
          variant="caption"
        >
          {label}
        </Typography>
      )}
      <TextField
        value={inputValue}
        onChange={handlePhoneValueChange}
        type="tel"
        inputRef={inputRef}
        sx={{
          height: "46px", // Adjust height of TextField
          borderRadius: "8px", // Add borderRadius to TextField
          "& .MuiInputBase-root": {
            height: "100%", // Ensure the input takes full height
            borderRadius: "8px", // Add borderRadius to InputBase
          },
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" style={{ marginRight: "2px", marginLeft: "-8px" }}>
              <Select
                MenuProps={{
                  style: {
                    height: "300px",
                    width: "360px",
                    top: "10px",
                    left: "-34px",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                }}
                sx={{
                  width: "max-content",
                  height: "46px", // Adjust height of Select
                  borderRadius: "8px", // Add borderRadius to Select
                  // Remove default outline (display only on focus)
                  fieldset: {
                    display: "none",
                  },
                  '&.Mui-focused:has(div[aria-expanded="false"])': {
                    fieldset: {
                      display: "block",
                    },
                  },
                  // Update default spacing
                  ".MuiSelect-select": {
                    padding: "8px",
                    paddingRight: "24px !important",
                    height: "100%", // Ensure the select takes full height
                  },
                  svg: {
                    right: 0,
                  },
                }}
                value={country.iso2}
                onChange={(e) => setCountry(e.target.value)}
                renderValue={(value) => <FlagImage iso2={value} style={{ display: "flex" }} />}
              >
                {defaultCountries.map((c) => {
                  const country = parseCountry(c);
                  return (
                    <MenuItem key={country.iso2} value={country.iso2}>
                      <FlagImage iso2={country.iso2} style={{ marginRight: "8px" }} />
                      <Typography marginRight="8px">{country.name}</Typography>
                      <Typography color="#8F92A1">+{country.dialCode}</Typography>
                    </MenuItem>
                  );
                })}
              </Select>
            </InputAdornment>
          ),
        }}
        {...restProps}
      />
    </FormControl>
  );
};

export default MuiPhone;
