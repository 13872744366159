import { getBlob, getDownloadURL, ref, uploadBytes, uploadBytesResumable } from "firebase/storage";

import { storage } from "../firebase/app";

// Function to convert a Blob to Base64
const blobToBase64 = (blob: Blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};

// Function to get Base64 string from Firebase Storage
const getBase64FromFirebase = async (path: string | undefined) => {
  try {
    // Step 1: Get the download URL
    const fileRef = ref(storage, path); // Reference to the file in Storage
    const blob = await getBlob(fileRef); // Get download URL

    // Step 3: Convert Blob to Base64
    const base64String = await blobToBase64(blob);
    return base64String; // Return the Base64 string
  } catch (error) {
    console.error("Error getting Base64 from Firebase:", error);
    throw error; // Propagate the error
  }
};

// Function to get Base64 string from Firebase Storage
const handleDownloadFromGcs = async (path: string) => {
  return await new Promise((resolve, reject) => {
    getDownloadURL(ref(storage, path))
      .then((url: any) => resolve(url))
      .catch((e) => reject(e));
  });
};

const handleUpload = async (acceptedFiles: any, path: string | undefined) => {
  return await new Promise((resolve, reject) => {
    for (const file of acceptedFiles) {
      const storageRef = ref(storage, path); // Create a reference to the file
      uploadBytes(storageRef, file)
        .then((r) => {
          resolve(r);
          console.log(`File ${file.name} uploaded successfully.`);
        })
        .catch((e) => {
          console.error("Error uploading file:", e);
          reject(e);
        }); // Upload the file
    }
  });
};

type onProgressValue = (progress: number) => void;
const handleUploadByPercent = async (
  acceptedFiles: any,
  path: string | undefined,
  onProgressValue: onProgressValue
) => {
  return await new Promise((resolve, reject) => {
    for (const file of acceptedFiles) {
      const storageRef = ref(storage, path); // Create a reference to the file

      // Create a reference for resumable upload
      const uploadTask = uploadBytesResumable(storageRef, file);

      // Monitor the upload progress
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Get progress information
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log(`Upload is ${progress.toFixed(2)}% done`);
          onProgressValue(progress);
        },
        (error) => {
          console.error("Error uploading file:", error);
          reject(error);
        },
        () => {
          // Handle successful uploads on complete
          uploadTask.then((snapshot) => {
            resolve(snapshot);
            console.log(`File ${file.name} uploaded successfully.`);
          });
        }
      );
    }
  });
};

export { handleDownloadFromGcs, handleUpload, handleUploadByPercent, getBase64FromFirebase };
