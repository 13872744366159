import { Box, IconButton, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { CustomBox } from "../../components/box/custom-box";
import IconLabel from "../../components/icon-label";
import YearIcon from "../../assets/icon/year";
import MakeIcon from "../../assets/icon/make";
import ModelIcon from "../../assets/icon/model";
import PriceIcon from "../../assets/icon/price";
import { handleCopyClipboard, removeTrailingZeros } from "../../utils/utils";
import MileageIcon from "../../assets/icon/mileage";
import VINIcon from "../../assets/icon/vin";
import CopyIcon from "../../assets/icon/copy";
import { formattedNumber } from "../../utils/string";
import CustomizeBox from "../../components/customize-box";

export default function RenderCarInfo({ lead, setMessage }: any) {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));

  const renderCarInformation = (
    <CustomizeBox>
      {!isXs && (
        <Typography variant="body2" fontWeight={"500"} color={"#8F92A1"} my={1}>
          Car Information
        </Typography>
      )}
      <CustomBox p={1}>
        <Box
          sx={{
            display: "grid",
            gap: 2,
            gridTemplateColumns: "repeat(2, auto)",
            width: "100%",
            mb: 1,
          }}
        >
          <IconLabel icon={<YearIcon />} label={lead?.year || "-"} />
          <IconLabel icon={<ModelIcon />} label={lead?.model || "-"} />
          <IconLabel icon={<MakeIcon />} label={lead?.make || "-"} />
          <IconLabel
            icon={<PriceIcon />}
            label={(lead?.priceOnWebsite && removeTrailingZeros(lead?.priceOnWebsite)) || "-"}
          />
          <IconLabel
            icon={<MileageIcon />}
            label={(lead?.mileage && formattedNumber(lead?.mileage)) || "-"}
          />
        </Box>
        <Box display={"flex"} justifyContent={"space-between"} width="100%" alignItems={"center"}>
          <Box display={"flex"} gap={1}>
            <IconLabel icon={<VINIcon />} label={lead?.VINNumber || "-"} />
          </Box>
          <IconButton
            color="inherit"
            disabled={!lead?.VINNumber}
            onClick={() => {
              handleCopyClipboard(lead?.VINNumber);
              setMessage({
                message: "VIN Number successfully copy.",
                open: true,
                type: "",
              });
            }}
          >
            <CopyIcon />
          </IconButton>
        </Box>
      </CustomBox>
    </CustomizeBox>
  );
  return renderCarInformation;
}
