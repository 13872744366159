import { Box, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  formatPhoneNumber,
  handleBackground,
  handleBGColor,
  handleCopyClipboard,
} from "../../utils/utils";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import BorderColorRoundedIcon from "@mui/icons-material/BorderColorRounded";
import CopyIcon from "../../assets/icon/copy";
import { getContactName, truncateString } from "../../utils/string";
import { convertTimestamp, formatMonthFromTimestamp } from "../../utils/time-convert";
import { CustomBox } from "../../components/box/custom-box";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import DialIcon from "../../assets/icon/dial";
import IconButton from "../../components/icon-button";
import CustomizeBox from "../../components/customize-box";
import EmailIcon from "../../assets/icon/email";
import { useSelector } from "react-redux";
import { getUid } from "../../redux/authSlice";
import { getActiveConId } from "../../redux/userSlice";
import { updateData } from "../../utils/firebase";
import StartIcon from "../../assets/icon/star";
import StarFillIcon from "../../assets/icon/star-fill";
import Button from "../../components/button/button";

function IconText({ icon, title, background, color, caption }: any) {
  return (
    <>
      <Box
        display={"flex"}
        sx={{
          padding: 1,
          borderRadius: 1,
          background: background,
          color: color,
        }}
      >
        {icon}
      </Box>
      <Box>
        <Typography variant="body2" fontWeight={"bold"} ml={1}>
          {title}
        </Typography>
        {caption && (
          <Typography variant="caption" ml={1}>
            {caption}
          </Typography>
        )}
      </Box>
    </>
  );
}

const handleTitle = (leadState: string) => {
  switch (leadState) {
    case "hot":
      return "Hot Lead";
    case "cold":
      return "Cold Lead";
    case "contactAttemped":
      return "Warm Lead";
    default:
      return "Cold Lead";
  }
};

type CustomizedTitleProps = {
  title: string;
  value: string;
};

const CustomizedTitle = ({ title, value }: CustomizedTitleProps) => {
  return (
    <>
      <Box justifyContent={"center"} textAlign={"left"} flexDirection={"column"}>
        <Box>
          <Typography
            variant="caption"
            fontWeight={"bold"}
            color={"#8F92A1"}
            textTransform={"capitalize"}
          >
            {title}
          </Typography>
        </Box>
        <Box>
          <Typography
            variant="caption"
            fontWeight={"bold"}
            color={(theme) => theme.palette.text.primary}
          >
            {value}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default function LeadInfo({
  handleOpenStatusMenu,
  handleOpenBug,
  correctUser,
  handleUpdateLead,
  lead,
  setMessage,
}: any) {
  const uid = useSelector(getUid);
  const conID = useSelector(getActiveConId);

  const [bookMarked, setBookMarked] = useState(false);

  useEffect(() => {
    if (correctUser?.bookMarked) setBookMarked(correctUser.bookMarked);
    else setBookMarked(false);
  }, [correctUser, conID]);

  const bookMarkedHandler = () => {
    const path = `conversations/${uid}/${conID}`;
    const data = { bookMarked: !bookMarked };
    updateData(path, data).then(() => setBookMarked(!bookMarked));
  };

  const renderAppointment = (
    <Box
      bgcolor={
        correctUser?.appointmentIsSet ? "rgba(206, 235, 248, 0.25)" : "rgba(143, 146, 161, 0.05)"
      }
      borderRadius={3}
      display={"flex"}
      border={correctUser?.appointmentIsSet ? "1px solid #07A4FC" : "none"}
      sx={{
        display: "flex",
        justifyContent: "between",
        alignItems: "center",
      }}
      width={"100%"}
    >
      {correctUser?.appointmentIsSet ? (
        <IconText
          icon={
            <EventAvailableIcon
              sx={{
                animation: "ripple 1.3s infinite ease-in-out",
                "@keyframes ripple": {
                  "0%": {
                    opacity: 1,
                    transform: "scale(.8)",
                  },
                  "100%": {
                    opacity: 0,
                    transform: "scale(1.1)",
                  },
                },
              }}
            />
          }
          title={"Appointment Booked"}
          background={"#07A4FC1A"}
          color={"rgba(7, 164, 252, 1)"}
          caption={convertTimestamp(correctUser?.appointmentTime)}
        />
      ) : (
        <IconText icon={<EventAvailableIcon />} title={"No Appointment Booked"} />
      )}
    </Box>
  );

  const renderConLink = (
    <CustomBox display={"flex"} width={"100%"} p={1} borderRadius={3} my={1}>
      <Box>
        <Typography
          variant="body2"
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          fontWeight={500}
        >
          Conversation Link
        </Typography>
        <Typography
          variant="caption"
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          fontWeight={500}
          color={"#8F92A1"}
        >
          {truncateString(window.location.href, 30)}
        </Typography>
      </Box>
      <IconButton
        onClick={() => {
          handleCopyClipboard(window.location.href);
          setMessage({
            message: "Conversation link successfully copy.",
            open: true,
            type: "",
          });
        }}
        icon={<CopyIcon />}
      />
    </CustomBox>
  );

  const renderLeadInformation = (
    <Box display={"flex"} flexDirection={"column"} px={1}>
      <Box display={"flex"} justifyContent={"space-between"} flexWrap={"wrap"}>
        <CustomizedTitle title="source" value={correctUser?.source || "-"} />
        <CustomizedTitle
          title="start chat"
          value={
            (correctUser?.startChatTs && formatMonthFromTimestamp(correctUser?.startChatTs)) || "-"
          }
        />
        <CustomizedTitle title="location" value={correctUser?.city || "California"} />
      </Box>
      {renderConLink}
      {renderAppointment}
    </Box>
  );

  const renderLeadInfo = (
    <CustomizeBox>
      <Box
        display={"flex"}
        gap={1}
        alignItems={"start"}
        width={"100%"}
        p={1}
        mb={1}
        sx={{
          background: correctUser?.isCarSold ? "#FF000026" : handleBGColor(correctUser?.leadState),
          borderRadius: 20,
        }}
        border={correctUser?.isCarSold ? `1px solid red` : ""}
        justifyContent={"space-between"}
      >
        {correctUser?.isCarSold ? (
          <Box gap={1} display={"flex"} flexDirection={"column"} width={"100%"}>
            <Typography
              color={(theme) => theme.palette.error.main}
              variant="h6"
              textAlign={"center"}
            >
              Sold
            </Typography>
          </Box>
        ) : (
          <Box display={"flex"} flexDirection={"column"} width={"100%"}>
            <Box display={"flex"} justifyContent={"space-between"}>
              <Box display={"flex"} alignItems={"center"}>
                {handleBackground(correctUser, false, "small")}
                <Typography variant="body2" fontWeight={500} ml={1} color={"white"}>
                  {handleTitle(correctUser?.leadState)}
                </Typography>
                <IconButton
                  icon={<ExpandMoreRoundedIcon />}
                  color={"white"}
                  onClick={handleOpenStatusMenu}
                />
              </Box>
              <Box display={"flex"} alignItems={"center"}>
                <Button
                  onClick={handleOpenBug}
                  sx={{
                    ":hover": {
                      borderColor: "transparent",
                    },
                    color: "white",
                    borderColor: "white",
                    borderRadius: 20,
                  }}
                  variant="outlined"
                >
                  Report Issues
                </Button>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
      <Box display={"flex"} flexDirection={"column"}>
        <Box display={"flex"} justifyContent={"space-between"}>
          <Box display={"flex"} alignItems={"center"}>
            <Typography fontWeight={"bold"} variant="h5">
              {getContactName(correctUser)}
            </Typography>
            <IconButton
              icon={bookMarked ? <StarFillIcon fill="#EDC045" /> : <StartIcon />}
              onClick={bookMarkedHandler}
            />
          </Box>
          <Box>
            <IconButton onClick={handleUpdateLead} icon={<BorderColorRoundedIcon />} />
          </Box>
        </Box>
        <Box display={"flex"} justifyContent={"space-between"}>
          <Box display={"flex"} alignItems={"center"}>
            <IconButton
              icon={<DialIcon />}
              onClick={() => {
                handleCopyClipboard(lead?.phoneNumber);
                setMessage({
                  message: "Phone number successfully copy.",
                  open: true,
                  type: "",
                });
              }}
            />
            <Typography variant="body2" fontWeight={400}>
              {(lead?.phoneNumber && formatPhoneNumber(lead?.phoneNumber)) || "-"}
            </Typography>
          </Box>
          <IconButton
            disabled={!lead?.phoneNumber}
            color="inherit"
            onClick={() => {
              handleCopyClipboard(lead?.phoneNumber);
              setMessage({
                message: "PhoneNumber successfully copy.",
                open: true,
                type: "",
              });
            }}
            icon={<CopyIcon />}
          />
        </Box>
        <Box display={"flex"} justifyContent={"space-between"}>
          <Box display={"flex"} alignItems={"center"}>
            <IconButton
              onClick={() => {
                handleCopyClipboard(lead?.email);
                setMessage({
                  message: "Email successfully copy.",
                  open: true,
                  type: "",
                });
              }}
              icon={<EmailIcon />}
            />
            <Typography variant="body2" fontWeight={400}>
              {lead?.email ? (
                <Tooltip title={lead?.email}>
                  <span>{truncateString(lead?.email, 24)}</span>
                </Tooltip>
              ) : (
                "-"
              )}
            </Typography>
          </Box>
          <IconButton
            disabled={!lead?.email}
            onClick={() => {
              handleCopyClipboard(lead?.email);
              setMessage({
                message: "Email successfully copy.",
                open: true,
                type: "",
              });
            }}
            icon={<CopyIcon />}
          />
        </Box>
      </Box>
      {renderLeadInformation}
    </CustomizeBox>
  );
  return renderLeadInfo;
}
