import * as React from "react";
import dayjs, { Dayjs } from "dayjs";
import Badge from "@mui/material/Badge";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { PickersDay, PickersDayProps } from "@mui/x-date-pickers/PickersDay";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { DayCalendarSkeleton } from "@mui/x-date-pickers/DayCalendarSkeleton";
import { Box } from "@mui/material";

function getRandomNumber(min: number, max: number) {
  return Math.round(Math.random() * (max - min) + min);
}

function fakeFetch(date: Dayjs, { signal }: { signal: AbortSignal }) {
  return new Promise<{ daysToHighlight: number[] }>((resolve, reject) => {
    const timeout = setTimeout(() => {
      const daysInMonth = date.daysInMonth();
      const daysToHighlight = [1, 2, 3].map(() => getRandomNumber(1, daysInMonth));
      resolve({ daysToHighlight });
    }, 500);

    signal.onabort = () => {
      clearTimeout(timeout);
      reject(new DOMException("aborted", "AbortError"));
    };
  });
}

const initialValue = dayjs("2024-04-17");

function ServerDay(
  props: PickersDayProps<Dayjs> & {
    highlightedDays?: number[];
    selectedDays?: Dayjs[];
    onDayClick?: (day: Dayjs) => void;
  }
) {
  const {
    // highlightedDays = [],
    day,
    outsideCurrentMonth,
    selectedDays = [],
    onDayClick,
    ...other
  } = props;

  const isSelected = selectedDays.some((selectedDay) => selectedDay.isSame(day, "day"));
  // const isHighlighted = !outsideCurrentMonth && highlightedDays.indexOf(day.date()) >= 0;

  return (
    <Badge key={day.toString()} overlap="circular" badgeContent={isSelected ? "" : undefined}>
      <div onClick={() => onDayClick?.(day)}>
        <PickersDay
          {...other}
          outsideCurrentMonth={outsideCurrentMonth}
          day={day}
          selected={isSelected}
        />
      </div>
    </Badge>
  );
}

export default function DateCalendarServerRequest({ selectedDays, setSelectedDays }: any) {
  const requestAbortController = React.useRef<AbortController | null>(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [highlightedDays, setHighlightedDays] = React.useState([1, 2, 15]);

  const fetchHighlightedDays = (date: Dayjs) => {
    const controller = new AbortController();
    fakeFetch(date, {
      signal: controller.signal,
    })
      .then(({ daysToHighlight }) => {
        setHighlightedDays(daysToHighlight);
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.name !== "AbortError") {
          throw error;
        }
      });

    requestAbortController.current = controller;
  };

  React.useEffect(() => {
    fetchHighlightedDays(initialValue);
    return () => requestAbortController.current?.abort();
  }, []);

  const handleMonthChange = (date: Dayjs) => {
    if (requestAbortController.current) {
      requestAbortController.current.abort();
    }

    setIsLoading(true);
    setHighlightedDays([]);
    fetchHighlightedDays(date);
  };

  const handleDayClick = (day: Dayjs) => {
    setSelectedDays((prev: any[]) => {
      if (
        prev.some((selectedDay: { isSame: (arg0: dayjs.Dayjs, arg1: string) => any }) =>
          selectedDay.isSame(day, "day")
        )
      ) {
        // Remove the day if it's already selected
        return prev.filter(
          (selectedDay: { isSame: (arg0: dayjs.Dayjs, arg1: string) => any }) =>
            !selectedDay.isSame(day, "day")
        );
      }
      // Add the day if it's not selected
      return [...prev, day];
    });
  };

  return (
    <Box display={"flex"} flexDirection={"column"}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateCalendar
          defaultValue={initialValue}
          loading={isLoading}
          onMonthChange={handleMonthChange}
          renderLoading={() => <DayCalendarSkeleton />}
          slots={{
            day: (dayProps) => (
              <ServerDay
                {...dayProps}
                highlightedDays={highlightedDays}
                selectedDays={selectedDays}
                onDayClick={handleDayClick}
              />
            ),
          }}
        />
      </LocalizationProvider>
      {/* <Box display={"flex"}>
        <h3>Selected Days:</h3>
        <p>{formatSelectedDays()}</p>
      </Box> */}
    </Box>
  );
}
