import React, { useEffect, useMemo, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { handleDownloadFromGcs } from "../../utils/gcs";
import { Box, CircularProgress } from "@mui/material";
import Viewer from "./viewer";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import IconButton from "../icon-button";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import ZoomInRoundedIcon from "@mui/icons-material/ZoomInRounded";
import ZoomOutRoundedIcon from "@mui/icons-material/ZoomOutRounded";

// Set the worker source
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "/pdfjs-dist/legacy/build/pdf.worker.min.js",
  window.location.origin
).toString();

function PdfViewer({ src, onClose, open }: any) {
  const [numPages, setNumPages] = useState<number>();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [generateSrc, setGenerateSrc] = useState("");
  const [scale, setScale] = useState<number>(1); // Scale variable for zoom

  const options = useMemo(
    () => ({
      cMapPacked: true,
      cMapUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/cmaps/`,
    }),
    []
  );

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }): void => {
    setNumPages(numPages);
  };

  useEffect(() => {
    if (src) {
      handleDownloadFromGcs(src)
        .then((r: any) => setGenerateSrc(r))
        .catch((e) => console.log(e));
    }
  }, [src]);

  const handleChangePageNumber = (increment: number) => {
    setPageNumber((prev) => Math.min(Math.max(prev + increment, 1), numPages || 1));
  };

  const renderDocument = useMemo(() => {
    if (!open || !generateSrc) return null; // Return null if not open or no source

    return (
      <Document
        loading={<CircularProgress />}
        file={generateSrc}
        onLoadSuccess={onDocumentLoadSuccess}
        options={options} // Use memoized options
      >
        <Page pageNumber={pageNumber} height={window.innerHeight * 0.8} scale={scale} />
      </Document>
    );
  }, [open, generateSrc, pageNumber, options, scale]);

  return (
    <Viewer onClose={onClose} open={open}>
      <Box
        position={"absolute"}
        display={"flex"}
        left={"1%"}
        bottom={"1%"}
        gap={1}
        flexDirection={"column"}
      >
        <p>
          Page {pageNumber} of {numPages}
        </p>
      </Box>
      {renderDocument}
      <Box
        position={"absolute"}
        display={"flex"}
        right={"1%"}
        bottom={"1%"}
        gap={1}
        flexDirection={"column"}
      >
        <Box display={"grid"} gridTemplateColumns={"repeat(2, 1fr)"}>
          <IconButton
            sx={{ zIndex: 5000 }}
            onClick={() => setScale((prev) => Math.min(prev + 0.1, 3))} // Zoom In
            icon={<ZoomInRoundedIcon fontSize="large" color="info" />}
          />
          <IconButton
            sx={{ zIndex: 5000 }}
            onClick={() => setScale((prev) => Math.max(prev - 0.1, 0.5))} // Zoom Out
            icon={<ZoomOutRoundedIcon fontSize="large" color="info" />}
          />
          <IconButton
            disabled={pageNumber === 1}
            onClick={() => handleChangePageNumber(-1)}
            icon={<ArrowBackIosNewRoundedIcon fontSize="large" color="info" />}
          />
          <IconButton
            disabled={numPages === pageNumber}
            onClick={() => handleChangePageNumber(1)}
            icon={<ArrowForwardIosRoundedIcon fontSize="large" color="info" />}
          />
        </Box>
      </Box>
    </Viewer>
  );
}

export default PdfViewer;
