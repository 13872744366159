import React from "react";
import ManageSearchRoundedIcon from "@mui/icons-material/ManageSearchRounded";
import { Avatar } from "@mui/material";

export const handleBGColor = (leadState: any) => {
  switch (leadState) {
    case "hot":
      return "#FD9292";
    case "contactAttemped":
      return "#EDC045";
    case "cold":
      return "#07A4FC";
    default:
      return "#07A4FC";
  }
};

const handleSrc = (leadState: any) => {
  switch (leadState) {
    case "cold":
      return "./images/cold-lead-state.svg";
    case "hot":
      return "./images/hot-lead-state.svg";
    case "contactAttemped":
      return "./images/warm-lead-state.svg";
    default:
      return "./images/cold-lead-state.svg";
  }
};

export const handleBackground = (
  con: { leadState: string | undefined },
  search = false,
  size = "large"
) => {
  if (search)
    return (
      <ManageSearchRoundedIcon fontSize="large" sx={{ alignItems: "center", display: "flex" }} />
    );
  return (
    <Avatar
      sx={{ width: size === "large" ? 45 : 27, height: size === "large" ? 45 : 27 }}
      src={handleSrc(con?.leadState)}
      alt="cold-lead-state"
    />
  );
};

export function formatNumber(num: { toLocaleString: () => any }) {
  if (!num) return "-";
  return num.toLocaleString();
}

export function removeTrailingZeros(num: { toString: () => string }) {
  if (!num) return "-";
  return `$${num
    ?.toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    .split(".")[0]
    .replace("$", "")}`;
}

export const handleCopyClipboard = (text: string) => {
  navigator.clipboard
    .writeText(text)
    .then(function () {
      // console.log('Text copied to clipboard');
    })
    .catch(function (err) {
      console.error("Error copying text to clipboard", err);
    });
};

export const handleName = (name: string | any) => {
  // Check if the input is a string
  if (typeof name !== "string") {
    return name?.charAt(0);
  }

  // Split the full name into first and last name
  const nameParts = name.trim().split(" ");

  // Check if the name has at least two parts
  if (nameParts.length < 2) {
    return name.charAt(0)?.toUpperCase() + name.charAt(1)?.toUpperCase();
  }

  // Get the first character of the first and last name
  const firstInitial = nameParts[0][0]?.toUpperCase();
  const lastInitial = nameParts[1][0]?.toUpperCase();

  // Return the initials
  return `${firstInitial}${lastInitial}`;
};

function validateMobileNumber(number: string) {
  const regex = /^\+?[1-9]\d{1,14}$/;
  return regex.test(number);
}

export function formatPhoneNumber(number: string) {
  if (validateMobileNumber(number)) {
    // Remove any non-digit characters
    const cleaned = number.replace(/\D/g, "");
    // Check if the cleaned number has the expected length
    if (cleaned.length === 11 && cleaned.startsWith("1")) {
      const areaCode = cleaned.slice(1, 4);
      const centralOfficeCode = cleaned.slice(4, 7);
      const lineNumber = cleaned.slice(7);
      return `1 (${areaCode}) ${centralOfficeCode}-${lineNumber}`;
    } else {
      throw new Error("Invalid phone number format");
    }
  } else return "";
}

export async function fetchData(query: any, token: string) {
  const path = `${process.env.REACT_APP_SEARCH_BASE_URL}?search=${query}`;
  try {
    const response = await fetch(path, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json", // adjust if necessary
      },
    });

    if (!response.ok) {
      throw new Error("Network response was not ok " + response.statusText);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("There was a problem with the fetch operation:", error);
  } finally {
    console.error("There was a problem with the fetch operation:");
  }
}

export const convertTo24Hour = (time: string, period: string) => {
  const [hours, minutes] = time.split(":").map(Number);
  let convertedHours = hours; // Use let for hours since it may change

  if (period.toLowerCase() === "pm" && convertedHours < 12) {
    convertedHours += 12;
  } else if (period.toLowerCase() === "am" && convertedHours === 12) {
    convertedHours = 0;
  }

  // Format hours and minutes to ensure two digits
  const formattedHours = String(convertedHours).padStart(2, "0");
  const formattedMinutes = String(minutes).padStart(2, "0");

  return `${formattedHours}:${formattedMinutes}`;
};

export const handleAddQueryParam = (newValue: string) => {
  // Update the URL without triggering a rerender
  const newUrl = newValue;
  window.history.pushState({ path: newUrl }, "", newUrl);
};

export function customizeSourceName(source: string) {
  if (source === "OFFERUP") return "OfferUp";
  // Join the words back into a single string
  if (source === "CARFAX, INC")
    return source.slice(0, 6); // remove , INC
  // Join the words back into a single string
  else return source;
}

export function generateUID() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = crypto.getRandomValues(new Uint8Array(1))[0] % 16 | 0;
    const v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}
