import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import Dialog from "../../components/dialog/dialog";
import React, { useEffect } from "react";
import SearchControl from "../../components/search-control/search-control";
import { activeConIdSet, handleOpenChat, searchedKeySet } from "../../redux/userSlice";
import { useSelector } from "react-redux";
import { getToken, handleToken } from "../../redux/authSlice";
import { fetchData, handleAddQueryParam } from "../../utils/utils";
import Animation from "../../components/animation";
import animationData from "../../assets/lottie/empty.json";
import { truncateString } from "../../utils/string";
import useDebounce from "../../hooks/useDebounce";
import { useAppDispatch } from "../../redux/hooks";

const SearchDialog = ({ open, handleClose }: any) => {
  const dispatch: any = useAppDispatch();
  const [results, setResults] = React.useState<any>([]);
  const [searchTerm, setSearchTerm] = React.useState<any>("");
  const [loading, setLoading] = React.useState<boolean>(false);
  const token = useSelector(getToken);

  // Use debounce to limit API calls
  const debouncedSearchTerm = useDebounce(searchTerm, 300);

  useEffect(() => {
    if (!debouncedSearchTerm) {
      setResults([]);
      return;
    }

    const searchHandler = async () => {
      setLoading(true);
      fetchData(searchTerm, token)
        .then((r) => {
          setResults(r);
          setLoading(false);
        })
        .catch((e) => {
          console.log(e);
          setLoading(false);
        });
    };

    searchHandler();
  }, [debouncedSearchTerm]);

  const handleSearchValue = (e: any) => {
    setSearchTerm(e.target.value);
  };

  const closeHandler = () => {
    setSearchTerm("");
    handleClose();
  };

  // update token
  useEffect(() => {
    if (open) dispatch(handleToken());
  }, [open]);

  const renderTitle = "Search";

  const renderContent = (
    <Box
      display={"flex"}
      justifyContent={"center"}
      flexDirection={"column"}
      width={"100%"}
      alignItems={"center"}
      p={1}
    >
      <SearchControl
        onChange={handleSearchValue}
        autoFocus={true}
        onClick={() => setSearchTerm("")}
        value={searchTerm}
        placeholder={"VIN, Phone, Name, Email"}
        showClose={searchTerm !== ""}
      />
    </Box>
  );

  const renderActions = (
    <Box
      height={"40rem"}
      display={"flex"}
      flexDirection={"column"}
      width={"100%"}
      alignItems={"center"}
    >
      {loading && <CircularProgress />}
      {searchTerm && results && Object.values(results).length > 0 && (
        <>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Full Name</TableCell>
                  <TableCell>Phone</TableCell>
                  <TableCell>Email</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.entries(results).map(([key, item]: [string, any]) => {
                  const child: any = Object.values(item)[0];
                  const keyData: any = Object.keys(item);
                  // TODO: fix child
                  return (
                    <TableRow
                      key={key}
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        dispatch(searchedKeySet(keyData));
                        closeHandler();
                        dispatch(handleOpenChat(true));
                        handleAddQueryParam(keyData);
                        dispatch(activeConIdSet(keyData));
                      }}
                    >
                      <TableCell>
                        <Tooltip title={child?.firstName + " " + child?.lastName}>
                          <span>
                            {truncateString(child?.firstName + " " + child?.lastName, 20)}
                          </span>
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        <Tooltip title={child?.phone}>
                          <span>{child?.phone || "-"}</span>
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        <Tooltip title={child?.email}>
                          <span>{truncateString(child?.email, 30) || "-"}</span>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}

      {searchTerm && results && Object.values(results).length === 0 && (
        <>
          <Animation
            animationData={animationData}
            autoplay
            loop
            style={{
              height: 350,
              width: 350,
            }}
          />
          <Typography>oops nothing found!</Typography>
        </>
      )}
    </Box>
  );

  return (
    <Dialog
      open={open}
      fullWidth
      handleClose={closeHandler}
      renderTitle={renderTitle}
      renderContent={renderContent}
      renderActions={renderActions}
    />
  );
};

export default SearchDialog;
