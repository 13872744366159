import React, { memo } from "react";
import { Avatar, Box, Chip, ListItemButton, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  activeConIdSet,
  getActiveConId,
  handleOpenChat,
  searchedKeySet,
} from "../../redux/userSlice";
import { convertTime } from "../../utils/time";
import AIDisabledIcon from "../../assets/icon/ai-disabled";
import FollowupIDisabledIcon from "../../assets/icon/followup-disabled";
import LineIcon from "../../assets/icon/line";
import { getMode } from "../../redux/layoutSlice";
import { customizeSourceName, handleAddQueryParam } from "../../utils/utils";
import { getContactName } from "../../utils/string";
import RenderCircle from "../../components/renderCircle";
import StartIcon from "../../assets/icon/star";
import StarFillIcon from "../../assets/icon/star-fill";
import { updateData } from "../../utils/firebase";
import { getUid } from "../../redux/authSlice";
import IconButton from "../../components/icon-button";

const ConversationItem = ({
  con,
  handleSelectItem,
  index,
  setCurrentUser,
  search,
  handleClose,
}: any) => {
  const conId = useSelector(getActiveConId);
  const selectedConId = conId === index;
  const dispatch = useDispatch();
  const mode = useSelector(getMode);

  const uid = useSelector(getUid);

  const handleClick = () => {
    if (search) {
      dispatch(searchedKeySet(index));
      handleClose();
    } else {
      setCurrentUser(con);
      handleSelectItem(index);
    }
    dispatch(handleOpenChat(true));
    handleAddQueryParam(index);
    dispatch(activeConIdSet(index));
  };

  const handleBG = () => {
    if (con?.unreadCounter > 0) return mode === "dark" ? "#3F496D" : "#eaf2f7";
    if (!selectedConId) return mode === "dark" ? "#272B39" : "#fff";
    else return "#cce1ec";
  };

  const handleSrc = () => {
    switch (con?.leadState) {
      case "cold":
        return "./images/cold-lead-state.svg";
      case "hot":
        return "./images/hot-lead-state.svg";
      case "contactAttemped":
        return "./images/warm-lead-state.svg";
      default:
        return "./images/cold-lead-state.svg";
    }
  };

  const renderBorder = () => {
    if (con?.unreadCounter > 0) return mode === "dark" ? "#3F496D" : "#969696";
    if (!selectedConId) return mode === "dark" ? "#3F496D" : "#969696";
    if (mode === "dark") return;
    if (selectedConId) return "blue";
  };

  const showItem = () => {
    if (
      con?.appointmentIsSet ||
      con?.callRequest ||
      con?.AIMode === false ||
      con?.followUpEnabled === false
    )
      return true;
    else return false;
  };

  const heightHandler = () => {
    if (con?.AIMode === false && con?.followUpEnabled === false) return "6rem";
    if (con?.AIMode === false || con?.appointmentIsSet || con?.callRequest) return "5rem";
    else return "3rem";
  };

  const bookMarkedHandler = () => {
    const path = `conversations/${uid}/${con?.pushId}`;
    const data = { bookMarked: !con?.bookMarked };
    updateData(path, data).then(() => {
      setCurrentUser({ ...con, bookMarked: !con?.bookMarked });
    });
  };

  return (
    <ListItemButton
      onClick={handleClick}
      onPointerDown={(e) => {
        e.currentTarget.style.backgroundColor = "transparent";
      }}
      sx={{
        pl: 0,
        margin: "auto",
        width: "98%",
        border: `1px solid ${renderBorder()}`,
        borderRadius: 3,
        mb: 1,
        backgroundColor: handleBG(),
        display: "flex",
        "&:hover": {
          backgroundColor: handleBG(), // or any other color you want
        },
      }}
      selected={mode === "dark" && selectedConId}
    >
      {selectedConId && <LineIcon fill={"blue"} sx={{ height: heightHandler() }} />}
      <Box width={"100%"}>
        <Box display={"flex"} gap={1}>
          <Avatar
            sx={{ width: 45, height: 45, ml: selectedConId ? 0 : 1 }}
            src={handleSrc()}
            alt="cold-lead-state"
          >
            {con?.firstName || con?.lastName
              ? ((con?.firstName !== "" && con?.firstName?.charAt(0)?.toUpperCase()) || "") +
                "" +
                ((con?.lastName !== "" && con?.lastName?.charAt(0)?.toUpperCase()) || "")
              : con?.phoneNumber
                ? con?.phoneNumber.slice(2, 5)
                : ""}
          </Avatar>
          <Box display={"flex"} flex={"1 1 0"} flexDirection={"column"}>
            <Box justifyContent={"space-between"} display={"flex"}>
              <Box display={"flex"}>
                <Typography
                  display={"flex"}
                  alignItems={"center"}
                  fontWeight={"500"}
                  variant="body1"
                  color={(theme) => theme.palette.text.primary}
                >
                  {getContactName(con)}
                </Typography>
                <Box display={"flex"} mx={0.2}>
                  {!con?.bookMarked ? (
                    <IconButton sx={{ p: 0 }} icon={<StartIcon />} onClick={bookMarkedHandler} />
                  ) : (
                    <IconButton
                      sx={{ p: 0 }}
                      icon={<StarFillIcon fill="#EDC045" />}
                      onClick={bookMarkedHandler}
                    />
                  )}
                </Box>
              </Box>
              <Box>
                <Typography variant="caption">
                  {(con?.lastMessage?.timestamp && convertTime(con?.lastMessage?.timestamp)) ||
                    convertTime(con?.timestamp)}
                </Typography>
              </Box>
            </Box>
            <Box justifyContent={"space-between"} display={"flex"}>
              <Typography variant="body2" color={"#8F92A1"} fontWeight={"500"}>
                {customizeSourceName(con?.source)}
                {con?.carData &&
                  " - " + con?.carData?.year + " " + con?.carData?.make + " " + con?.carData?.model}
              </Typography>
              {!con?.read ? (
                <Box display="flex">
                  <RenderCircle length={con?.unreadCounter} />
                  {con?.msgCount && (
                    <>
                      <Box color={"#7D7D7D"} mx={0.2}>
                        /
                      </Box>
                      <Typography variant="caption" fontWeight={600}>
                        {con?.msgCount}
                      </Typography>
                    </>
                  )}
                </Box>
              ) : (
                con?.msgCount && (
                  <Typography variant="caption" fontWeight={600}>
                    {con?.msgCount}
                  </Typography>
                )
              )}
            </Box>
          </Box>
        </Box>
        {showItem() && (
          <Box ml={selectedConId ? 0 : 1} mt={1} gap={1} display={"flex"} alignItems={"center"}>
            <Box display={"flex"} flex={1} gap={1}>
              {con?.appointmentIsSet && (
                <Chip
                  label="Appt Booked"
                  sx={{
                    width: "auto",
                    height: "auto",
                    py: "4px",
                    fontSize: 12,
                    color: "#2EA071",
                    bgcolor: "#CDECEC",
                  }}
                />
              )}
              {con?.callRequest && (
                <Chip
                  label="Callback Request"
                  sx={{
                    width: "auto",
                    height: "auto",
                    py: "4px",
                    fontSize: 12,
                    color: "#AD00EA",
                    bgcolor: "#BE0FFB26",
                  }}
                />
              )}
            </Box>
            <Box display={"flex"} flexDirection={"column"} alignItems={"end"}>
              {con?.AIMode === false && (
                <Box display={"flex"} alignItems={"center"}>
                  <Typography variant="caption" pr={1} color={"#8F92A1"}>
                    A.I. Disabled
                  </Typography>
                  <AIDisabledIcon
                    sx={{
                      width: 14,
                      height: 14,
                      color: "#FB5353",
                    }}
                  />
                </Box>
              )}
              {con?.followUpEnabled === false && (
                <Box display={"flex"} alignItems={"center"}>
                  <Typography variant="caption" pr={1} color={"#8F92A1"}>
                    Follow Up Disabled
                  </Typography>
                  <FollowupIDisabledIcon sx={{ width: 14, height: 14, color: "#FF0000" }} />
                </Box>
              )}
            </Box>
          </Box>
        )}
      </Box>
    </ListItemButton>
  );
};

export default memo(ConversationItem);
