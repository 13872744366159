import * as React from "react";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import { Stack, useMediaQuery, useTheme } from "@mui/material";
import DirectionsCarRoundedIcon from "@mui/icons-material/DirectionsCarRounded";

import LeftSidebar from "./left-sidebar";
import Navbar from "./navbar";
import { bottomHeight, navAndBottomHeightPx, navHeight, navHeightPx } from "../config/constant";
import BottomNavigation from "./navigation";
import { useSelector } from "react-redux";
import { getActiveConId } from "../redux/userSlice";
import Button from "../components/button/button";
import Messenger from "../assets/icon/messenger";
import { useLocation, useNavigate } from "react-router";
import AppointmentIcon from "../assets/icon/appointment-icon";
import ReportIcon from "../assets/icon/report";

const drawerWidth = 240;

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    "& .MuiDrawer-paper": {
      position: "relative",
      whiteSpace: "nowrap",
      width: drawerWidth,
      borderRight: "none",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: "border-box",
      ...(!open && {
        overflowX: "hidden",
        transition: theme.transitions.create("width", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up("sm")]: {
          width: theme.spacing(9),
        },
      }),
    },
  })
);

export default function DashboardLayout({ children }: any) {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const activeConId = useSelector(getActiveConId);
  const navigate = useNavigate();

  const { pathname } = useLocation();
  const appointment = pathname === "/appointment";
  const report = pathname === "/report";
  const setting = pathname.includes("/setting");
  const cars = pathname === "/cars";
  const trainingRequest = pathname === "/training-request";
  const inbox = !cars && !setting && !report && !appointment && !trainingRequest;
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleOpen = () => {
    setOpen(!open);
  };

  const renderLeftDrawer = (
    <>
      {isSmall ? (
        <MuiDrawer anchor={"left"} open={open} onClose={handleOpen}>
          <List
            component="nav"
            sx={{
              borderRight: "none",
              width: 100,
              display: "flex",
              flexDirection: "column",
              height: "100%",
              justifyContent: "space-between",
            }}
          >
            <LeftSidebar />
          </List>
        </MuiDrawer>
      ) : (
        <Drawer
          variant="permanent"
          open={open}
          sx={{
            paddingTop: navHeight,
          }}
        >
          <List
            component="nav"
            sx={{
              borderRight: "none",
              display: "flex",
              flexDirection: "column",
              height: "100%",
              justifyContent: "space-between",
            }}
          >
            <LeftSidebar openSidebar={open} />
          </List>
        </Drawer>
      )}
    </>
  );

  return (
    <Box sx={{ display: "flex" }}>
      {isSmall ? !activeConId && <Navbar setOpen={handleOpen} /> : <Navbar setOpen={handleOpen} />}
      {renderLeftDrawer}
      <Box
        component="main"
        height={
          isSmall
            ? activeConId
              ? "100dvh"
              : `calc(100vh - ${navAndBottomHeightPx})`
            : `calc(100vh - ${navHeightPx})`
        }
        sx={{
          flexGrow: 1,
          overflow: "auto",
          m: isSmall
            ? activeConId
              ? 0
              : theme.spacing(`${navHeightPx} 0 ${bottomHeight}px 0`)
            : theme.spacing(`${navHeightPx} 0 0 0`),
          display: "flex",
          flexDirection: "column",
          p: isMobile ? 0 : 1,
          pt: 1,
        }}
      >
        <Stack
          sx={{
            borderTopLeftRadius: 30,
            backgroundColor: (theme) => theme.palette.background.default,
            display: { xs: "none", md: "flex" },
          }}
          flex={0}
          m={1}
        >
          <Box
            width={"100%"}
            bgcolor={"white"}
            margin={"auto"}
            borderRadius={3}
            gap={1}
            display={"flex"}
            p={1}
          >
            <Button
              variant="text"
              sx={{
                display: "flex",
                bgcolor: (theme: any) => theme.palette.secondary.main,
                color: (theme: any) =>
                  inbox ? theme.palette.primary.main : theme.palette.text.primary,
                px: 2,
                fontWeight: "bold",
                fontSize: "1rem",
              }}
              startIcon={<Messenger />}
              onClick={() => navigate("/")}
            >
              Inbox
            </Button>
            <Button
              variant="text"
              sx={{
                display: "flex",
                bgcolor: (theme: any) => theme.palette.secondary.main,
                color: (theme: any) =>
                  appointment ? theme.palette.primary.main : theme.palette.text.primary,
                px: 2,
                fontWeight: "bold",
                fontSize: "1rem",
              }}
              startIcon={<AppointmentIcon />}
              onClick={() => navigate("/appointment")}
            >
              Appointment
            </Button>
            <Button
              variant="text"
              sx={{
                display: "flex",
                bgcolor: (theme: any) => theme.palette.secondary.main,
                color: (theme: any) =>
                  report ? theme.palette.primary.main : theme.palette.text.primary,
                px: 2,
                fontWeight: "bold",
                fontSize: "1rem",
              }}
              startIcon={<ReportIcon />}
              onClick={() => navigate("/report")}
            >
              Report
            </Button>
            <Button
              variant="text"
              sx={{
                display: "flex",
                bgcolor: (theme: any) => theme.palette.secondary.main,
                color: (theme: any) =>
                  cars ? theme.palette.primary.main : theme.palette.text.primary,
                px: 2,
                fontWeight: "bold",
                fontSize: "1rem",
              }}
              startIcon={<DirectionsCarRoundedIcon />}
              onClick={() => navigate("/cars")}
            >
              Inventory
            </Button>
          </Box>
        </Stack>
        <Stack
          sx={{
            borderTopLeftRadius: 30,
            backgroundColor: (theme) => theme.palette.background.default,
            overflowY: "scroll",
          }}
          flex={1}
          margin={"auto"}
          width={"99%"}
        >
          <Stack
            sx={{
              m: "auto",
              width: "100%",
              height: "100%",
              borderRadius: 3,
            }}
          >
            {children}
          </Stack>
        </Stack>
      </Box>
      {isSmall && !activeConId && <BottomNavigation />}
    </Box>
  );
}
