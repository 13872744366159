import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import { Box, Grid, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router";
import { activeConIdSet } from "../redux/userSlice";
import { useDispatch, useSelector } from "react-redux";
import Button from "./button/button";
import Dialog from "./dialog/dialog";
import { getUid } from "../redux/authSlice";
import { getData } from "../utils/firebase";
import CustomizeBox from "./customize-box";
import IconLabel from "./icon-label";
import YearIcon from "../assets/icon/year";
import ModelIcon from "../assets/icon/model";
import MakeIcon from "../assets/icon/make";
import PriceIcon from "../assets/icon/price";
import { formatPhoneNumber, handleCopyClipboard, removeTrailingZeros } from "../utils/utils";
import MileageIcon from "../assets/icon/mileage";
import { formattedNumber, truncateString } from "../utils/string";
import VINIcon from "../assets/icon/vin";
import IconButton from "./icon-button";
import CopyIcon from "../assets/icon/copy";
import DialIcon from "../assets/icon/dial";
import EmailIcon from "../assets/icon/email";

function AppointmentDialog({ open, handleClose, onRedirect, pushId }: any) {
  const uid = useSelector(getUid);

  const [data, setData]: any = useState(undefined);

  const getLeadData = () => {
    getData(`dealers/${uid}/leads/${pushId}`).then((r: any) => {
      const updateData: any = {};
      updateData.VINNumber = r?.VINNumber;
      updateData.make = r?.make;
      updateData.model = r?.model;
      updateData.mileage = r?.mileage;
      updateData.year = r?.year;
      updateData.priceOnWebsite = r?.priceOnWebsite;
      updateData.phoneNumber = r?.phoneNumber;
      updateData.fullName = r?.firstName + " " + r?.lastName;
      setData(updateData);
    });
  };

  useEffect(() => {
    if (open) getLeadData();
  }, [open]);

  const renderContent = (
    <Box display={"flex"} flexDirection={"column"} width={"100%"} gap={1}>
      <CustomizeBox>
        <Typography fontWeight={"700"} variant="body1">
          {data?.fullName}
        </Typography>
        <Box display={"flex"} justifyContent={"space-between"}>
          <Box display={"flex"} alignItems={"center"}>
            <IconButton
              icon={<DialIcon />}
              onClick={() => {
                handleCopyClipboard(data?.phoneNumber);
              }}
            />
            <Typography variant="body2" fontWeight={400}>
              {(data?.phoneNumber && formatPhoneNumber(data?.phoneNumber)) || "-"}
            </Typography>
          </Box>
          <IconButton
            disabled={!data?.phoneNumber}
            color="inherit"
            onClick={() => {
              handleCopyClipboard(data?.phoneNumber);
            }}
            icon={<CopyIcon />}
          />
        </Box>
        <Box display={"flex"} justifyContent={"space-between"}>
          <Box display={"flex"} alignItems={"center"}>
            <IconButton
              onClick={() => {
                handleCopyClipboard(data?.email);
              }}
              icon={<EmailIcon />}
            />
            <Typography variant="body2" fontWeight={400}>
              {data?.email ? (
                <Tooltip title={data?.email}>
                  <span>{truncateString(data?.email, 24)}</span>
                </Tooltip>
              ) : (
                "-"
              )}
            </Typography>
          </Box>
          <IconButton
            disabled={!data?.email}
            onClick={() => {
              handleCopyClipboard(data?.email);
            }}
            icon={<CopyIcon />}
          />
        </Box>

        <Box
          sx={{
            display: "grid",
            gap: 2,
            gridTemplateColumns: "repeat(2, auto)",
            width: "100%",
            mb: 1,
          }}
        >
          <IconLabel icon={<YearIcon />} label={data?.year || "-"} />
          <IconLabel icon={<ModelIcon />} label={data?.model || "-"} />
          <IconLabel icon={<MakeIcon />} label={data?.make || "-"} />
          <IconLabel
            icon={<PriceIcon />}
            label={(data?.priceOnWebsite && removeTrailingZeros(data?.priceOnWebsite)) || "-"}
          />
          <IconLabel
            icon={<MileageIcon />}
            label={(data?.mileage && formattedNumber(data?.mileage)) || "-"}
          />
        </Box>
        <Box display={"flex"} justifyContent={"space-between"} width="100%" alignItems={"center"}>
          <Box display={"flex"} gap={1}>
            <IconLabel icon={<VINIcon />} label={data?.VINNumber || "-"} />
          </Box>
          <IconButton
            color="inherit"
            disabled={!data?.VINNumber}
            onClick={() => {
              handleCopyClipboard(data?.VINNumber);
            }}
          >
            <CopyIcon />
          </IconButton>
        </Box>
      </CustomizeBox>
      <Button onClick={onRedirect} variant="contained">
        Go to Conversation
      </Button>
    </Box>
  );

  return <Dialog open={open} handleClose={handleClose} fullWidth renderContent={renderContent} />;
}

const Calendar = ({ events }: any) => {
  const [open, setOpen] = useState(false);
  const [data, setData]: any = useState(undefined);

  const navigate = useNavigate();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();

  const handleToggled = () => {
    setOpen(!open);
  };

  // a custom render function
  function renderEventContent(eventInfo: {
    timeText:
      | string
      | number
      | boolean
      | React.ReactElement<any, string | React.JSXElementConstructor<any>>
      | Iterable<React.ReactNode>
      | React.ReactPortal
      | null
      | undefined;
    event: {
      title:
        | string
        | number
        | boolean
        | React.ReactElement<any, string | React.JSXElementConstructor<any>>
        | Iterable<React.ReactNode>
        | React.ReactPortal
        | null
        | undefined;
    };
  }) {
    return (
      <Box display={"flex"} gap={1}>
        <Button variant="contained" color="primary">
          {eventInfo.event.title}
        </Button>
      </Box>
    );
  }

  return (
    <Grid
      container
      justifyContent={"center"}
      bgcolor={theme.palette.background.default}
      p={{ xs: 0, md: 1 }}
      borderRadius={5}
    >
      <Grid
        item
        xs={12}
        md={11}
        bgcolor={theme.palette.background.paper}
        borderRadius={5}
        padding={{ xs: 0, md: 1 }}
      >
        <Box
          p={1}
          sx={{
            "& .fc .fc-toolbar.fc-header-toolbar": {
              flexDirection: { xs: "column", sm: "row" },
            },
            "& .fc-daygrid-event-harness": {
              display: "flex",
              justifyContent: "center",
            },
            "& .fc-timegrid-event-harness-inset .fc-timegrid-event, .fc-timegrid-event.fc-event-mirror, .fc-timegrid-more-link":
              {
                background: "transparent",
              },
            "& .fc-v-event .fc-event-main": {
              color: (theme) => theme.palette.primary.main,
              fontWeight: 700,
            },
            "& .fc .fc-timegrid-axis-cushion": {
              maxWidth: "max-content",
            },
          }}
        >
          <FullCalendar
            height={"75vh"}
            plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin]}
            headerToolbar={{
              left: "prev,next today",
              center: "title",
              right: isXs
                ? "timeGridDay,timeGridWeek,listMonth"
                : "timeGridDay,timeGridWeek,dayGridMonth,listMonth",
            }}
            initialView={isXs ? "listMonth" : "dayGridMonth"}
            events={events}
            selectMirror={true}
            dayMaxEvents={true}
            eventContent={renderEventContent}
            eventClick={(event) => {
              const { VINNumber, source, fullName, phoneNumber, email, make, model, year, key } =
                event.event._def.extendedProps;
              handleToggled();
              const data: any = {
                source: source,
                fullName: fullName,
                phoneNumber: phoneNumber,
                email: email,
                make: make,
                model: model,
                year: year,
                VINNumber: VINNumber,
                pushId: key,
              };
              setData(data);
            }}
            fixedWeekCount={true}
            showNonCurrentDates={false}
          />
        </Box>
      </Grid>
      <AppointmentDialog
        open={open}
        handleClose={handleToggled}
        data={data}
        setData={setData}
        pushId={`/${data?.pushId}`}
        onRedirect={() => {
          navigate(`/${data?.pushId}`);
          dispatch(activeConIdSet(data?.pushId));
        }}
      />
    </Grid>
  );
};

export default Calendar;
