import { Box, SelectChangeEvent, Typography } from "@mui/material";
import React, { useEffect } from "react";
import Selector from "../../components/select/select";
import CustomizedSwitches from "../../components/switch/customize-switch";
import { convertTo24Hour } from "../../utils/utils";
import Button from "../../components/button/button";
import ControlledBox from "../../components/box/controlled-box";
import { clockData } from "../../config/constant";
import axios from "axios";
import { useSelector } from "react-redux";
import { getToken, getUid } from "../../redux/authSlice";
import { getData } from "../../utils/firebase";

const handleChangeFormat = (array: any[]) => {
  // Use map to create a new array with an added property
  const updatedArrays = array.map(({ start, end, morning, midnight, ...rest }: any) => {
    return {
      ...rest, // Spread the existing properties
      time_range: `${start}${morning.toUpperCase()}-${end}${midnight.toUpperCase()}`, // Optional (if not closed)
    };
  });
  return updatedArrays;
};

// Transform to the new format
const resetArrays = (array: any[]) => {
  return array.map(({ time_range, appointment_only, day }: any) => {
    const [startTime, endTime] = time_range.split("-"); // Split the time_range
    const start = startTime.slice(0, -2); // Get the start time (without AM/PM)
    const end = endTime.slice(0, -2); // Get the end time (without AM/PM)
    const morning = startTime.slice(-2).toLowerCase(); // Get AM/PM for start
    const midnight = endTime.slice(-2).toLowerCase(); // Get AM/PM for end
    return {
      start,
      end,
      morning,
      midnight,
      appointment_only,
      closed,
      day,
    };
  });
};

const initialValue = [
  {
    day: "Monday",
    start: "8:00",
    end: "5:00",
    morning: "am",
    midnight: "pm",
    closed: false,
    appointment_only: false,
  },
  {
    day: "Tuesday",
    start: "8:00",
    end: "5:00",
    morning: "am",
    midnight: "pm",
    closed: false,
    appointment_only: false,
  },
  {
    day: "Wednesday",
    start: "8:00",
    end: "5:00",
    morning: "am",
    midnight: "pm",
    closed: false,
    appointment_only: false,
  },
  {
    day: "Thursday",
    start: "8:00",
    end: "5:00",
    morning: "am",
    midnight: "pm",
    closed: false,
    appointment_only: false,
  },
  {
    day: "Friday",
    start: "8:00",
    end: "5:00",
    morning: "am",
    midnight: "pm",
    closed: false,
    appointment_only: false,
  },
  {
    day: "Saturday",
    start: "8:00",
    end: "5:00",
    morning: "am",
    midnight: "pm",
    closed: false,
    appointment_only: false,
  },
  {
    day: "Sunday",
    start: "8:00",
    end: "5:00",
    morning: "am",
    midnight: "pm",
    closed: false,
    appointment_only: false,
  },
];

export default function BusinessHours() {
  const [week, setWeek]: any = React.useState(initialValue);

  const token = useSelector(getToken);
  const uid = useSelector(getUid);

  const handleGetData = (data: any) => {
    if (!data) return;
    const updateDate = resetArrays(Object.values(data));
    setWeek(updateDate);
  };

  useEffect(() => {
    getData(`dealers/${uid}/settings/time_operation/days`).then((r: any) => handleGetData(r));
  }, []);

  const postData = async () => {
    // Create an Axios instance with a base URL
    const apiClient = axios.create({
      baseURL: process.env.REACT_APP_BASE_URL, // Replace with your base URL
      headers: {
        Authorization: ` ${token}`, // Replace with your actual token
        "Content-Type": "application/json",
      },
    });
    const data = {
      section: "time_operation",
      data: {
        days: handleChangeFormat(week),
      },
    };
    try {
      const response = await apiClient.post("", data);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeDay = (day: string, updates: object) => {
    setWeek((prevWeek: any) =>
      prevWeek.map((item: any) => (item.day === day ? { ...item, ...updates } : item))
    );
  };

  const handleChange = (day: string) => {
    setWeek((prevWeek: any) =>
      prevWeek.map((item: any) => (item.day === day ? { ...item, disabled: !item.closed } : item))
    );
  };

  return (
    <>
      <ControlledBox>
        <Typography variant="h6" fontWeight={700}>
          Hours of Operations
        </Typography>
        {week.map((w: any, id: any) => (
          <Box key={id} display={"flex"}>
            <Box
              width={113}
              height={80}
              border={"1px solid #E3E3E3"}
              display={"flex"}
              justifyContent={"center"}
              flexDirection={"column"}
              gap={1}
              borderRadius={1}
              p={1}
              my={1}
            >
              <Typography display={"flex"} variant="body2" color={"#7D7F80"}>
                {w.day}
              </Typography>
              <Typography display={"flex"} variant="body2" color={"#7D7F80"}>
                {convertTo24Hour(w.start, w.morning)} - {convertTo24Hour(w.end, w.midnight)}
              </Typography>
            </Box>
            <Box ml={1}>
              <Typography>From</Typography>
              <Box display={"flex"} gap={1}>
                <Selector
                  disabled={w.closed}
                  data={clockData}
                  width={85}
                  value={w.start}
                  onChange={(e: SelectChangeEvent) =>
                    handleChangeDay(w.day, {
                      start: e.target.value,
                      morning: w.morning,
                      end: w.end,
                      midnight: w.midnight,
                    })
                  }
                />
                <Selector
                  data={["am", "pm"]}
                  disabled={w.closed}
                  width={85}
                  value={w.morning}
                  onChange={(e: SelectChangeEvent) =>
                    handleChangeDay(w.day, {
                      start: w.start,
                      morning: e.target.value,
                      end: w.end,
                      midnight: w.midnight,
                    })
                  }
                />
              </Box>
            </Box>
            <Box ml={2}>
              <Typography>To</Typography>
              <Box display={"flex"} gap={1}>
                <Selector
                  data={clockData}
                  width={85}
                  value={w.end}
                  disabled={w.closed}
                  onChange={(e: SelectChangeEvent) =>
                    handleChangeDay(w.day, {
                      start: w.start,
                      morning: w.morning,
                      end: e.target.value,
                      midnight: w.midnight,
                    })
                  }
                />
                <Selector
                  data={["am", "pm"]}
                  width={85}
                  value={w.midnight}
                  disabled={w.closed}
                  onChange={(e: SelectChangeEvent) =>
                    handleChangeDay(w.day, {
                      start: w.start,
                      morning: w.morning,
                      end: w.end,
                      midnight: e.target.value,
                    })
                  }
                />
              </Box>
            </Box>
            <Box display={"flex"} justifyContent={"space-between"} mx={1} alignItems={"center"}>
              <Box mr={1}>
                <Typography variant="body2" fontWeight={"bold"}>
                  Appointment Only
                </Typography>
              </Box>
              <CustomizedSwitches name="AIMode" checked={w.closed} />
            </Box>
            <Box display={"flex"} justifyContent={"space-between"} mx={1} alignItems={"center"}>
              <Box mr={1}>
                <Typography variant="body2" fontWeight={"bold"}>
                  Closed
                </Typography>
              </Box>
              <CustomizedSwitches
                onChange={() => handleChange(w.day)}
                checked={w.closed}
                name="AIMode"
              />
            </Box>
          </Box>
        ))}
      </ControlledBox>
      <Box display={"flex"} justifyContent={"center"} width={"100%"} gap={1}>
        <Button variant="outlined" color="primary">
          Cancel
        </Button>
        <Button variant="contained" color="primary" onClick={postData}>
          Save Change
        </Button>
      </Box>
    </>
  );
}
